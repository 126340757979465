<template>
  <div
    class="he-ui-info-box he-ui-font-small-bold d-flex align-items-start justify-content-start align-items-center"
    :class="`status-${status}`">
    <span class="me-4"><img :src="getIcon" /></span>
    <div class="w-100">
      <span class="me-2 he-ui-info-text">{{ getTextByStatus }}</span>
    </div>
  </div>
</template>

<script>
import { FINGERS_LEFT_HAND, FINGERS_RIGHT_HAND, HANDS } from '@/constants/constants'
import imgStarBlue from '@/assets/star_blue.svg'
import imgStatusFinished from '@/assets/check_ring_border.svg'
import imgStatusIndications from '@/assets/info_border.svg'

export default {
  props: {
    status: {
      type: String,
      validator (value) {
        return [
          'initial',
          'indications',
          'predefined',
          'finished'
        ].includes(value)
      }
    },
    hand: {
      type: Number,
      default: null
    },
    finger: {
      type: Number,
      default: null
    },
    multifingerInfo: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      HANDS,
      iconStarBlue: imgStarBlue,
      iconStatusIndications: imgStatusIndications,
      iconStatusFinished: imgStatusFinished,
      selectedHand: this.hand,
      selectedfinger: this.finger
    }
  },
  computed: {
    isStatusIndications () {
      return this.status === 'indications'
    },
    getIcon () {
      switch (this.status) {
        case 'initial':
          return null
        case 'indications':
          return this.iconStatusIndications
        case 'predefined':
          return this.iconStarBlue
        case 'finished':
          return this.iconStatusFinished
        default:
          return ''
      }
    },
    getTextByStatus () {
      switch (this.status) {
        case 'initial':
          return this.$t('main.place_your_finger')
        case 'indications':
          return this.formatHandAndFingerText()
        case 'predefined':
          return this.$t('main.recommended_fingerprint')
        case 'finished':
          return this.multifingerInfo ? this.formatHandAndFingerText() : this.$t('main.scanning_finished')
        default:
          return ''
      }
    }
  },
  watch: {
    hand (value) {
      this.selectedHand = value
    },
    finger (value) {
      this.selectedfinger = value
    }
  },
  methods: {
    getHandName () {
      return this.$t(Object.values(HANDS)[this.selectedHand].token)
    },
    getFingerName () {
      const hand = this.selectedHand ? FINGERS_RIGHT_HAND : FINGERS_LEFT_HAND
      const fingers = Object.values(hand)
      return this.$t(fingers.find(finger => finger.index === this.selectedfinger).token)
    },
    formatHandAndFingerText () {
      return this.getHandName() + ' - ' + this.getFingerName()
    }
  }
}
</script>

<style lang="scss" scoped>
.he-ui-info-box {
  border-radius: 8px;
  padding: 9px 25px;
  width: 450px;

  & .he-ui-info-text {
    font-weight: 700;
    font-size: 20px;
  }

  &.status-initial{
    background: $grey-info-back-color;
    opacity: 0;
    animation: blinker 1.4s linear infinite;
  }
  &.status-predefined {
    background: $grey-info-back-color;
    color: $grey-info-color;
  }
  @keyframes blinker {
    0% { opacity: 1; }
  }

  &.status-indications {
    background: rgb(54 138 255 / 11%);
    color: $blue-color;
    text-transform: uppercase;
  }

  &.status-finished {
    background: rgba(52, 199, 89, 0.08);
    color: $green-color;
    text-transform: uppercase;
  }
}
</style>
