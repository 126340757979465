<template>
  <Header
    :headerText="$t('main.fingerprints_stored')"
    :goBack="goBack"
    :goHome="goHome"
    :showBars="true"
    :allBarsActive="true"
  />
  <div class="d-flex flex-column justify-content-center align-items-center py-4 h-100">
    <div class="he-ui-stored-fingerprints-title he-ui-font-normal-bold">{{ $t('main.select_fingerprints_to_edit') }}</div>
    <div
      class="he-ui-stored-fingerprints-hands-wrapper bg-white d-flex align-items-center justify-content-center position-relative">
      <div
        v-for="(n, finger) in 5"
        :key="finger"
        class="he-ui-stored-fingerprints-hand-img"
        @click="(e) => onSelectHand(e, finger, HANDS.LEFT.index)"
        @mouseover.stop.prevent="mouseOver"
        @mouseout.stop.prevent="mouseOut">
        <img
          :src="getHands(HANDS.LEFT.index, finger, savedFingers)"
          :alt="`left-hand-${finger}`" />
      </div>
      <div
        v-for="finger in [...Array(10).keys()].slice(5).reverse()"
        :key="finger"
        class="he-ui-stored-fingerprints-hand-img"
        @click="(e) => onSelectHand(e, finger, HANDS.RIGHT.index)"
        @mouseover.stop.prevent="mouseOver"
        @mouseout.stop.prevent="mouseOut">
        <img
          :src="getHands(HANDS.RIGHT.index, finger, savedFingers)"
          :alt="`right-hand-${index}`" />
      </div>
      <div
        v-if="clicked"
        ref="itemMark"
        class="he-ui-item-mark" />
    </div>
    <div
      :class="selectedHand !== null ? 'justify-content-between' : 'justify-content-end'"
      class="d-flex w-100">
      <info-box
        v-if="selectedHand !== null"
        class="he-ui-info-box"
        :status="status"
        :hand="selectedHand"
        :finger="selectedFinger" />
      <div class="d-flex">
        <MainButton
          class="me-4"
          template="white"
          rounded
          uppercase
          :text="$t('main.edit')"
          :onClick="onEdit" />
        <MainButton
          template="blue"
          rounded
          uppercase
          :text="$t('main.save')"
          :onClick="onSave" />
      </div>
    </div>
    <portal to="modal-place">
      <SuccesModal
        v-if="showSuccessModal"
        :patientId="patientId"
        :isNewPatient="isNew"
        showButton="true"
        :message="$t('main.accept')"
        :onClicked="closeSuccessModal"/>
    </portal>
    <portal to="modal-place">
      <LeaveRegister
        v-if="showLeavingModal"
        :buttons="leavingModalButtons"
        :isNewPatient=true />
    </portal>
  </div>
</template>

<script>
import { HANDS, HHID } from '@/constants/constants'
import { MAIN_PAGE_ROUTE_NAME, SCAN_MULTIFINGER_PAGE_ROUTE_NAME } from '@/router/routes'
import { mapActions, mapMutations } from 'vuex'
import Header from '@/components/Header'
import InfoBox from '@/components/InfoBox'
import LeaveRegister from '@/components/LeaveRegister'
import MainButton from '@/components/MainButton'
import SuccesModal from '@/components/SuccesModal'
import handsCompositionFunction from '@/composition/handsCompositionFunction'
import mixins from '@/composition/mixins'

const PX_STR = 'px'

export default {
  name: 'StoredFingerprints',
  components: {
    Header,
    InfoBox,
    LeaveRegister,
    MainButton,
    SuccesModal
  },
  setup () {
    const { getHands } = handsCompositionFunction()
    const { sendDataToParentClient } = mixins()

    return {
      getHands,
      sendDataToParentClient
    }
  },
  data () {
    return {
      selectedFinger: null,
      selectedHand: null,
      HANDS,
      clicked: false,
      initialLeft: null,
      initialWidth: null,
      isNew: true,
      status: 'initial',
      savedFingers: [],
      showLeavingModal: false,
      showSuccessModal: false
    }
  },
  computed: {
    patientId () {
      return localStorage.getItem(HHID) ? localStorage.getItem(HHID) : null
    },
    leavingModalButtons () {
      return [
        { text: this.$t('main.continue_registration'), template: 'white', handler: () => { this.showLeavingModal = false } },
        { text: this.$t('main.exit'), template: 'blue', handler: () => { this.hideAndLeavePage() } }
      ]
    }
  },
  beforeMount () {
    this.fetchPatientFingerprints({ patientId: this.patientId })
      .then((response) => {
        this.savedFingers = response.map(finger => finger.fingerNumber)
      })
  },
  methods: {
    ...mapActions([
      'fetchPatientFingerprints',
      'deletePatient'
    ]),
    ...mapMutations([
      'resetPatientId',
      'setHandSelected',
      'resetHandSelected',
      'setFingerSelected',
      'resetFingerSelected'
    ]),
    onSelectHand (event, finger, hand) {
      this.selectedFinger = finger
      this.selectedHand = hand
      this.calculateItemMarkPosition(event)
    },
    calculateItemMarkPosition (event) {
      if (event) {
        this.clicked = true
        const target = event.target || event
        if (this.$refs.itemMark) {
          this.$refs.itemMark.style.left = target.offsetLeft + PX_STR
          this.$refs.itemMark.style.width = target.offsetWidth + PX_STR
          this.initialLeft = target.offsetLeft
          this.initialWidth = target.offsetWidth
        }
      }
    },
    mouseOver (e) {
      if (this.$refs.itemMark) {
        this.$refs.itemMark.style.width = e.target.offsetWidth + PX_STR
        this.$refs.itemMark.style.left = e.target.offsetLeft + PX_STR
      }
    },
    mouseOut (e) {
      if (this.$refs.itemMark) {
        this.$refs.itemMark.style.left = this.initialLeft + PX_STR
        this.$refs.itemMark.style.width = this.initialWidth + PX_STR
      }
    },
    onEdit () {
      if (this.selectedHand != null && this.selectedFinger != null) {
        this.setHandSelected(this.selectedHand)
        this.setFingerSelected(this.convertCorrectFingerTemp(this.selectedFinger))
      } else {
        this.resetHandSelected()
        this.resetFingerSelected()
      }
      this.goBack()
    },
    onSave () {
      this.sendDataToParentClient(this.patientId, this.isNew)
      this.showSuccessModal = true
    },
    convertCorrectFingerTemp (finger) {
      switch (finger) {
        case 9:
          return 0
        case 8:
          return 1
        case 7:
          return 2
        case 6:
          return 3
        case 5:
          return 4
        default:
          return finger
      }
    },
    closeSuccessModal () {
      this.canLeavePage = true
      this.showLeavingModal = false
      this.showSuccessModal = false
      this.resetPatientId()
      this.$router.push({
        name: MAIN_PAGE_ROUTE_NAME
      })
    },
    goBack () {
      this.$router.push({
        name: SCAN_MULTIFINGER_PAGE_ROUTE_NAME
      })
    },
    goHome () {
      this.showConfirmMessage()
    },
    showConfirmMessage () {
      this.showLeavingModal = true
    },
    hideAndLeavePage () {
      this.showLeavingModal = false
      this.canLeavePage = true
      // 2023-05-05 Dont delete patient temporarily, about an error (constraint violation) on backend (Ruben dixit)
      // this.deletePatient(this.patientId)
      //   .then(() => {
      this.goToMainPage()
      // })
      // .catch((err) => {
      //   console.error(err)
      // })
    },
    goToMainPage () {
      this.$router.push({ name: MAIN_PAGE_ROUTE_NAME })
    }
  }
}
</script>

<style lang="scss" scoped>
.he-ui-stored-fingerprints-title {
  width: 400px;
}
.he-ui-stored-fingerprints-hands-wrapper {
  margin: 4rem 0;
  padding: 37px 53px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 25px;
  @media (max-width: 1180px) {
    margin: 2rem 0;
  }
}

.he-ui-stored-fingerprints-hand-img {
  height: 5rem;
  min-width: 4rem;
  width: 4rem;
  cursor: pointer;
  @media (max-width: 1180px) {
    height: 4rem;
    min-width: 3rem;
    width: 3rem;
  }
  img {
    height: 100%;
    width: 100%;
  }
  +.he-ui-stored-fingerprints-hand-img {
    margin-left: 1rem;
  }
  &:nth-child(6n) {
    margin-left: 3rem;
  }
}
.he-ui-info-box {
  width: 473px;
}
.he-ui-item-mark {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 0.13rem;
  width: 50%;
  background: $blue-color;
  transition-property: left, width;
  transition-duration: .4s;
  transition-timing-function: linear;
}
</style>
